<template>
  <v-card class="docs request-docs">
    <v-card-title>
      <div class="text-h5">{{ title }}</div>
    </v-card-title>
    <table>
      <thead>
      <tr>
        <th>Параметр</th>
        <th>Данные</th>
        <th class="org-comment__flag">&nbsp;</th>
        <th class="org-comment__control">Контроль</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(question, questionIndex) in questions"
          :key="'q.'+question.id">
        <td>
          {{ questionIndex + 1 }}.
          {{ question.name }}, {{ question.unit }}
        </td>
        <td class="org-comment__params">
          <div class="standard-docs-params">
            <v-text-field
                :value="parseFloat(findValueById(indicatorValues, question.id)?.value)"
                @input="setValue(question.id, $event)"
                :disabled="(questionIsImported(question) && !checkRoles(controllerRoles)) || (!questionIsImported(question) && checkRoles(controllerRoles))"
                placeholder="Значение"
                type="number"
            ></v-text-field>
          </div>
        </td>
        <td class="org-comment__flag">
          <template v-if="!questionIsImported(question)">
            <div class="flag-choose">
              <div class="flag-choose__colors">
                <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                     fill="green">
                  <circle cx="7" cy="7" r="7"/>
                </svg>
                <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                     fill="yellow">
                  <circle cx="7" cy="7" r="7"/>
                </svg>
                <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                     fill="red">
                  <circle cx="7" cy="7" r="7"/>
                </svg>
              </div>
              <v-radio-group
                  :disabled="!checkRoles(controllerRoles)"
                  :value="findValueById(controlValues, question.id)?.flag"
                  @change="setControlFlag(question.id, $event)"
              >
                <v-radio :value="'GREEN'" style="width: 0; height: 20px"/>
                <v-radio :value="'YELLOW'" style="width: 0; height: 20px"/>
                <v-radio :value="'RED'" style="width: 0; height: 20px"/>
              </v-radio-group>
            </div>
          </template>
          <template v-else>
            Данные импортируются ({{ question.source }})
          </template>
        </td>
        <td v-if="!questionIsImported(question)">
          <v-textarea
              placeholder="Комментарий"
              :disabled="!checkRoles(controllerRoles)"
              :value="findValueById(controlValues, question.id)?.comment"
              @change="setControlComment(question.id, $event)"
          ></v-textarea>
        </td>
      </tr>
      </tbody>
    </table>

    <v-card-actions>
      <v-btn
          class="btn btn-outline"
          text
          @click="$emit('close')"
      >
        Отмена
      </v-btn>
      <v-btn
          class="btn btn-primary"
          @click="submit()"
      >
        Сохранить изменения
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  createEmptyControlValue,
  createEmptyIndicatorValue,
  findValueById
} from "@/modules/new/ResultMonitor";
import {saveIndicators} from "@/modules/api.indicators";
import {saveControls} from "@/modules/api.controls";
export default {
  name: 'ResultMonitorEditDialog',
  components: {},
  props: ['regionAndDepartmentInfo', 'title', 'questions', 'indicatorValues', 'controlValues', 'period'],

  data: () => ({
    flags: ['#89FF8E', '#F8FC56', '#FF7272'],
    answerStatuses: ['WELL', 'SATISFACTORILY', 'UNSATISFACTORY'],
    controllerRoles: ['ROLE_ADMIN', 'ROLE_VNII'],
  }),
  methods: {
    findValueById: findValueById,

    getOrCreateIndicatorValue(indicatorId) {
      let existingValue = findValueById(this.indicatorValues, indicatorId)
      if (existingValue == null) {
        existingValue = createEmptyIndicatorValue(this.regionAndDepartmentInfo, indicatorId, this.period)
        this.indicatorValues.push(existingValue)
      }
      return existingValue
    },

    getOrCreateControlInfo(indicatorId) {
      let existingValue = findValueById(this.controlValues, indicatorId)
      if (existingValue == null) {
        const level = this.getUserControlLevel()
        existingValue = createEmptyControlValue(this.regionAndDepartmentInfo, indicatorId, this.period, level)
        this.controlValues.push(existingValue)
      }
      return existingValue
    },

    setValue(indicatorId, value) {
      this.getOrCreateIndicatorValue(indicatorId).value = value
    },

    setControlFlag(indicatorId, value) {
      this.getOrCreateControlInfo(indicatorId).flag = value
    },

    setControlComment(indicatorId, value) {
      this.getOrCreateControlInfo(indicatorId).comment = value
    },

    checkRoles(userRoles) {
      for (let role of userRoles) {
        if (this.$user.subroles.includes(role)) {
          return true;
        }
      }
      return false;
    },

    getUserControlLevel() {
      switch (this.$user.role) {
        case 'ROLE_VNII':
        case 'ROLE_ADMIN':
          return 'VNII'
        case 'ROLE_MINTRUD':
          return 'MINTRUD'
        case 'ROLE_REGIONAL_AGENCY':
          return 'ROIV'
        case 'ROLE_CZN_MANAGER':
          return 'CZN'
      }
    },

    questionIsImported(question) {
      if (this.regionAndDepartmentInfo.department != null && this.regionAndDepartmentInfo.department.type !== 'manager_czn') {
        return false
      }
      return question.source !== 'РОИВ'
    },

    truncateStr(str, n) {
      if (!str) return ''
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    async submit() {
      await saveIndicators(this.indicatorValues)
      await saveControls(this.controlValues)
      this.$emit('updated', this);
    }
  },
  beforeMount() {
  }
}
</script>

<style scoped lang="scss">
.standard-docs-params {
  gap: 10px;
}

.standard-docs-file {
  padding-left: 25px;
  background: url(~@/assets/attach.svg) no-repeat left center;
}

.standard-docs-comment {
  background: url(~@/assets/chat.svg) no-repeat left center;
  padding-left: 25px;
}

.org-comment__control {
  min-width: 200px;
}

.org-comment__flag {
  & > div {
    position: relative;
    min-width: 40px;

    input[type="radio"] {
      display: none;
    }

    label {
      display: block;
      height: 16px;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        border-radius: 10px;
        background: #fff;
        border: 1px solid $cblue;
      }
    }

    input:checked + label:after {
      content: '';
      position: absolute;
      right: 4px;
      top: calc(50%);
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      transition: all 0.2s;
      background: $cblue;
    }

  }

  & > div + div {
    margin-top: 5px;
  }
}
</style>


